.headerImage {
    width: 50%;
    margin-left: 20%;
    margin-bottom: 5%;
}

.backgroundSplit {
    background-color: white !important;
}

ion-menu {
    --ion-background-color: rgb(255, 255, 255);
    max-width: 250px;
}

ion-card {
    --ion-background-color: rgb(255, 255, 255);
}

ion-tab-bar{
    --color-selected: #003466 !important;
}

.splitmenu{
    color:#868686 !important;
}

.stagingBanner {
    background-color: #ffa700;
    height: 50px;
    text-align: center;
    align-items: center;
    color: white;
    font-size: large;
    z-index: inherit;
    position: absolute;
    border-radius: 0px 0px 0px 10px;
    right:0;

}

.tableRowSpacing {
    height: 35px;
}

.adminSubHeadingBlue {
    color: #003466;
    font-size: 24px !important;
    font-style: normal;
    font-weight: bold !important;
    line-height: normal;
    letter-spacing: -0.48px;
}

.adminBlueDataText {
    color: #003466;
}

.pointer {
    cursor: pointer;
}

.multiCardRow {
    display: flex;
    align-items: stretch;
}

.multiCardCol {
    flex: 1;
}

.headerUnderline {
    border-bottom: #CACACA 1px solid;
}

.icon-only-button {
    --padding-start: 0;
    --padding-end: 0;
    width: 16px;
    height: 16px;
}

.auditModal {
    --width: 800px
}

.modal {
    --border-radius: 21px;
    --ion-background-color: #ffffff;
}
.border-top {
    border-top: 1px solid #B0B0B0;
}
.circularButton {
    --border-radius: 50%;
    width: 35px;
    height: 35px;
}

.circularButtonContent {
    flex-shrink: 0;
    padding-right: 1px;
}

.circularButton:active {
background-color: #003466;
border-radius: 50%;
width: 35px;
height: 35px;
}