.paymentDetailsStatusSuccess{
    color:#17DA36 !important;
    text-transform: lowercase;
}
.paymentDetailsStatusSuccess::first-letter{
    color:#17DA36 !important;
    text-transform: uppercase;
}

.paymentDetailsStatusPendingOrInProgress{
    color: #ffa700 !important;
    text-transform: lowercase;
}
.paymentDetailsStatusPendingOrInProgress::first-letter{
    color: #ffa700 !important;
    text-transform: uppercase;
}

.paymentDetailsStatusFailed{
    color: #EA1313 !important;
    text-transform: lowercase;
}
.paymentDetailsStatusFailed::first-letter{
    color: #EA1313 !important;
    text-transform: uppercase;
}