.file-drop-area {
    border: 2px dashed #ccc;
    border-radius: 20px;
    position: relative;
    width: 100%;
    max-width: 800px;
    padding: 40px;
    text-align: center;
    transition: border-color 0.3s;
    margin: auto;
  }
  
  .drag-over {
    border-color: #000;
  }
  
  .file-input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
  
  .drop-message {
    margin-top: 20px;
    font-size: 16px;
  }
  
  .upload-icon {
    /* You would replace the following line with your custom upload icon */
    background-image: url('/public/assets/img/upload_arrow.png');
    background-size: 100%;
    height: 50px; /* Set this to the size of your icon */
    width: 50px; /* Set this to the size of your icon */
    display: inline-block;
    margin-top: 20px;
  }
  