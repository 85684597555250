#accountSearchTitle {
font-weight: bold;
font-size: 24px;

}
#resultsTitle{
    font-size: 24px;
    color: #003466;
    font-weight: bold;
}
.accountSearchSubTitle {
font-weight: bold;
font-size: 18px;
}

.accountInputTitle {
    font-size: 16px;
    margin-top: 12px;
    color: #868686;
}

.accountSearchInput {
border-bottom: 1px solid #b0b0b0;
color: black !important;
}

.inputRow{
    margin-bottom: 10px;
}

.accountSearchButton{
    margin-top: 10px;
    text-align: end;
}
.itemTitleBorder{
    border-bottom: 1px solid #b0b0b0;
}
.itemTitle {
    font-size: 12px;
}
.adminInputWhite {
    border: 1px solid #858585;
    width: 270px;
    height: 30px;
    border-radius: 6px;
}
.errorMessage {
    color: #FF0404;

}