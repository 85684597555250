.onboardingStatusRed {
color: #EA1313;
}

.onboardingStatusYellow {
color: #ffa700 
}

.onboardingStatusGreen {
color: #17DA36;
}

.onboardingTitleRows {
    margin-left: 20px;
}

#onboardingTitle {
    font-weight: bold !important;
    font-size: 30px;
    color: #003466;
    margin-bottom: -10px;
}

#onboardingUsersName {
    font-weight: bold !important;
    font-size: 30px;
    color: #003466;
}
.onboardingSubTitles{
border-bottom: 1px solid #A7A7A7;
margin-bottom: 10px;
padding-bottom: 10px;
}

.viewAllButton{
    text-align:end;
}
.viewAllButton2{
    margin-right: 30px;
    text-decoration: underline;
    cursor: pointer;
}

.onboardingStageItems{
margin-top: 10px;
margin-bottom: 10px;
}
.viewItemLink {
    text-align: end;
}
#onboardingTableTitle{
font-size: 20px;
color: #003466;
font-weight: bold;
}
#questionnaireViewTitle{
    font-weight: bold;
    font-size: 18px;
    text-decoration: underline;
    color: #003466;
}

@media  (max-width:768px) {
    #onboardingTitle {
        font-weight: bold !important;
        font-size: 20px;
        color: #003466;
        margin-bottom: -10px;
    }
    
    #onboardingUsersName {
        font-weight: bold !important;
        font-size: 20px;
        color: #003466;
    }
    .onboardingTitleRows {
        margin-left: 0px;
    }
    .onboardingStageItems{
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 12px;
    }
    .questionnaireDetails{
        font-size: 10px;
    }
        

}