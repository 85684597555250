.paylinkResultMainTitle{
    font-size: 24px;
    font-weight:bold;
    color: #003466;
}
.paylinkResultTitle{
    font-size: 20px !important;
    font-weight:bold !important;
    color: #003466;
}
.paylinkResultDataTitle{
    color: #003466;
}
.paylinkResultData{
    color: #003466;
}
.paylinkResultDataStatus{
    text-transform: lowercase;
    color: #003466;
}
.paylinkResultDataStatus::first-letter{
    text-transform: uppercase;
    color: #003466;
}