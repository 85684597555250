ion-button {
    text-transform: none;
    --box-shadow: none;
    font-style: normal;
  }
  .submitButton {
    --border-radius: 15px;
  }
  .submitButtonSquare {
    --border-radius: 8px;
    --padding-top: 20px;
    --padding-bottom: 20px;
  }
  div.text-button {
    background-color: transparent;
    border: none;
    outline: none;
    text-decoration: underline !important;
    color: #fff;
  }
  .spinner-overlay {
    position: relative;
  }
  .spinner-overlay .text-content {
    visibility: hidden;
  }
  .spinner-overlay .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  