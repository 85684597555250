.toast {
   --background: white;
}

.toast::part(message) {
   font-size: 12px;
   color: #003466;
   text-align: center;
   font-weight: bold;
}

.toast::part(button) {
   color: white;
   border-radius: 2px;
}

.errorToast::part(button) {
   background-color: #ff0000;
}

.successToast::part(button) {
   background-color: #ff7100;
}
