.invoiceStatusSuccess{
    color:#17DA36 !important;
    text-transform: lowercase;
}
.invoiceStatusSuccess::first-letter{
    color:#17DA36 !important;
    text-transform: uppercase;
}

.invoiceStatusPendingOrInProgress{
    color: #ffa700 !important;
    text-transform: lowercase;
}
.invoiceStatusPendingOrInProgress::first-letter{
    color: #ffa700 !important;
    text-transform: uppercase;
}

.invoiceStatusFailed{
    color: #EA1313 !important;
    text-transform: lowercase;
}
.invoiceStatusFailed::first-letter{
    color: #EA1313 !important;
    text-transform: uppercase;
}
.invoiceIconDiv{
    display: none;
    width: 10px;
    margin-left: 20px;
}

.selectedPaymentText{
    font-size: 18px;
}

#invoicesSubTitle {
    color: #003466;
    font-size: 20px !important;
    font-weight: bold !important;
    margin-bottom: 10px !important;
}

.selectedPayment{
border-top: 1px solid #B0B0B0;

}
.paymentItem{
    cursor: pointer;
}
.paymentItem:hover{
    cursor: pointer;
}

.invoicesFilter{
    height: 26px;
    padding-left:20px ;
    padding-right:20px ;
    font-size: 10px;
    border-radius: 10px;
    background-color: #E5E5E5;
    --placeholder-color: #000000;
    --placeholder-opacity: 1;
    width: 100%;
    min-width: 100px;
    justify-content: left;
}
.invoicesFilter::part(icon){
    color: #000000;
}
.searchButton {
    width: auto;
}

ion-input {
    --placeholder-color: #ff7100;
    
}

.descriptionInput {
    font-size: 12px;

}
.titleDiv{
   box-shadow: 0px 1px 8px #888888;
   padding-top: 80px;
   padding-bottom: 25px;
   /* --background: url('/public/assets/img/background/pastTransactionsBackground.svg') no-repeat top left; */
}
.filterInvoicesDiv {
    display: flex;
    margin-bottom: 15px;
    
}
.filterInvoicesDiv > div {
    align-self: center;
}
.paymentID{
    font-size: 8px;
}
.transactionPageTitle {
    color: #003466; 
    text-align: left;
    font-weight: bold;

}
.datePicker{
    --title-color:#003466;
    color: #000000;
    margin: auto;
}
.dateTimeContent{
    text-align: center;
}

.paymentDetailsModal{
   --border-radius: 21px;
   --ion-background-color: #ECECEC;
}

.refreshIcon{
color: #4A4A4A
}
.statusOfPaymentTitle{
    margin-bottom: -10px;
}
.paymentHistoryDetailCloseButton
{
    margin-top: 20px;
}
.paymentHistoryBlock{
margin-bottom: 150px;
}
#invoicesMainTitle {
    font-weight: bold !important;
    font-size: 30px;
    color: #003466;
    margin-bottom: -10px;
}

#invoicesUsersName {
    font-weight: bold !important;
    font-size: 30px;
    color: #003466;
}
.invoiceItemTitle{
    font-size: 14px;
    color:#959595;
    border-bottom:1px solid #A7A7A7;
}
.invoiceItem{
    margin-top: 15px;
    margin-bottom: 25px;
    font-size: 12px;
    cursor: pointer;
    color: #003466;
}
.invoiceItem:hover{
    cursor: pointer;
}
@media (max-width: 768px){

    .invoiceStatusSuccess{
        color:#17DA36 !important;
        text-transform: lowercase;
        display: none;
    }
    .invoiceStatusSuccess::first-letter{
        color:#17DA36 !important;
        text-transform: uppercase;
        display: none;
    }
    
    .invoiceStatusPendingOrInProgress{
        color: #ffa700 !important;
        text-transform: lowercase;
        display: none;
    }
    .invoiceStatusPendingOrInProgress::first-letter{
        color: #ffa700 !important;
        text-transform: uppercase;
        display: none;
    }
    
    .invoiceStatusFailed{
        color: #EA1313 !important;
        text-transform: lowercase;
        display: none;
    }
    .invoiceStatusFailed::first-letter{
        color: #EA1313 !important;
        text-transform: uppercase;
        display: none;
    }
    .invoiceIconDiv{
        display: inline-block;
        width: 10px;
        margin-left: 20px;
    }
    .invoiceGrid{
        margin: -5px;
    }
    .transactionIDText {
        font-size: 9px !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important; 
        white-space: nowrap;
        color: #003466;
    }
    
    .transactionHeader{
        font-size: 9px;
        color: #868686;
        font-weight: bold;
    }
    
    .transactionText{
        font-size: 9px;
        color: #003466;
    }
    #invoicesMainTitle {
        font-weight: bold !important;
        font-size: 20px;
        color: #003466;
        margin-bottom: -10px;
    }
    
    #invoicesUsersName {
        font-weight: bold !important;
        font-size: 20px;
        color: #003466;
    }
    
    #invoicesSubTitle {
        color: #003466;
        font-size: 18px !important;
        font-weight: bold !important;
        margin-bottom: 10px !important;
    } 
    
    .transactionGrid{
        margin: -15px;
    }
    
    .paymentStatusDiv{
        display: none;
    }
    
    .iconDiv{
        display: inline-block;
        width: 10px;
        margin-left: 20px;
    }
    
    .invoiceItemTitle{
        font-size: 12px;
        color:#959595;
    }
    .invoiceItem{
        margin-top: 15px;
        margin-bottom: 25px;
        font-size: 10px;
        cursor: pointer;
    }
        
    }