#userTabBar{
    --background:#ffffff;
}

.tabText {
font-size: 10px;

}
@media(max-width:768px){
    .tabText{
        display: none;
    }
}