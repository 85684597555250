.paymentStatusSuccess{
    color:#17DA36 !important;
    text-transform: lowercase;
}
.paymentStatusSuccess::first-letter{
    color:#17DA36 !important;
    text-transform: uppercase;
}

.paymentStatusPendingOrInProgress{
    color: #ffa700 !important;
    text-transform: lowercase;
}
.paymentStatusPendingOrInProgress::first-letter{
    color: #ffa700 !important;
    text-transform: uppercase;
}

.paymentStatusFailed{
    color: #EA1313 !important;
    text-transform: lowercase;
}
.paymentStatusFailed::first-letter{
    color: #EA1313 !important;
    text-transform: uppercase;
}

#transactionsSubTitle {
    color: #003466;
    font-size: 20px !important;
    font-weight: bold !important;
    margin-bottom: 10px !important;
}

#transactionsMainTitle {
    font-weight: bold !important;
    font-size: 30px;
    color: #003466;
    margin-bottom: -10px;
}

#transactionsUsersName {
    font-weight: bold !important;
    font-size: 30px;
    color: #003466;
}
.selectedPaymentText{
    font-size: 18px;
}
.paymentItem{
    cursor: pointer;
    margin-left: -15px;
    margin-right: -6px;
    margin-top: 15px;
    margin-bottom: 25px;
}
.paymentItem:hover{
    cursor: pointer;
}
.selectedPayment{
border-top: 1px solid #B0B0B0;

}
.paymentHistorytitleRow {
    border-bottom:1px solid #A7A7A7;
}


.paymentHistoryFilter{
    display: flex;
  flex-wrap: wrap;
    margin-top: -15px;
    margin-bottom: 10px;
    margin-left: -15px;
    color: #003466;
}
.paymentHistoryFilter::part(icon){
    color: #000000;
}
.searchPaymentsButton {
    width: auto;
}

ion-input {
    --placeholder-color: #ff7100;
    
}
.testFilter{
    height: 26px;
    padding-left:10px;
    padding-right:20px;
    font-size: 12px;
    border-radius: 10px;
    background-color: #E5E5E5;
    --placeholder-color: #000000;
    --placeholder-opacity: 1;
    width: 100%;
    min-width: 100px;
    justify-content: left;
    margin-top: 20px;
}

.descriptionInput {
    font-size: 12px;
    background-color: #ffffff;
    border-radius: 12px;

}
.filterPaymentsDiv {
    display: flex;
    margin-bottom: 15px;
    
}

.filterPaymentsDiv > div {
    align-self: center;
}
.paymentID{
    font-size: 8px;
}
.transactionPageTitle {
    color: #003466; 
    text-align: left;
    font-weight: bold;

}
.datePicker{
    --title-color:#003466;
    color: #000000;
    margin: auto;
}
.dateTimeContent{
    text-align: center;
}

.paymentDetailsModal{
   --border-radius: 21px;
   --ion-background-color: #ECECEC;
}

.refreshIcon{
    color: #4A4A4A
}
.statusOfPaymentTitle{
    margin-bottom: -10px;
}
.paymentHistoryDetailCloseButton
{
    margin-top: 20px;
}
.paymentHistoryBlock{
    margin-bottom: 150px;
}
.transactionHeader{
    font-size: 11px;
    color: #868686;
    font-weight: bold;
}
.transactionText {
    font-size: 11px;
    color: #003466;
}
.transactionAmountText {
    font-size: 11px;
    margin-left: 10px;
    color: #003466;
}

.transactionIDText {
    font-size: 11px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;   
    color: #003466;
   
    
}

.paymentStatusDiv{
    display: inline-block;
}
.iconDiv{
    display: none;
    width: 10px;
    margin-left: 20px;
}

@media (max-width: 768px){
.transactionIDText {
    font-size: 9px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important; 
    white-space: nowrap;
    color: #003466;
}

.transactionHeader{
    font-size: 9px;
    color: #868686;
    font-weight: bold;
}

.transactionText{
    font-size: 9px;
    color: #003466;
}
#transactionsMainTitle {
    font-weight: bold !important;
    font-size: 20px;
    color: #003466;
    margin-bottom: -10px;
}

#transactionsUsersName {
    font-weight: bold !important;
    font-size: 20px;
    color: #003466;
}

#transactionsSubTitle {
    color: #003466;
    font-size: 18px !important;
    font-weight: bold !important;
    margin-bottom: 10px !important;
} 

.transactionGrid{
    margin: -15px;
}

.paymentStatusDiv{
    display: none;
}

.iconDiv{
    display: inline-block;
    width: 10px;
    margin-left: 20px;
}

.paymentStatusSuccess{
    color:#17DA36 !important;
    text-transform: lowercase;
    display: none;
}
.paymentStatusSuccess::first-letter{
    color:#17DA36 !important;
    text-transform: uppercase;
    display: none;
}

.paymentStatusPendingOrInProgress{
    color: #ffa700 !important;
    text-transform: lowercase;
    display: none;
}
.paymentStatusPendingOrInProgress::first-letter{
    color: #ffa700 !important;
    text-transform: uppercase;
    display: none;
}

.paymentStatusFailed{
    color: #EA1313 !important;
    text-transform: lowercase;
    display: none;
}
.paymentStatusFailed::first-letter{
    color: #EA1313 !important;
    text-transform: uppercase;
    display: none;
}
    
}


