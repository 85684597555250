.detailsTitle {
    color: #003466;
    font-size: 20px !important;
    font-weight: bold !important;
    margin-bottom: 10px !important;
}

#HomeTitle {
    font-weight: bold !important;
    font-size: 30px;
    color: #003466;
    margin-bottom: -10px;
}

#UsersName {
    font-weight: bold !important;
    font-size: 30px;
    color: #003466;
}

.editButtons {
    width: 80px;
}

.titleRows {
    margin-left: 30px;
}

.userDetailsCard {
    box-shadow: none;
    --background: #f1efef !important;
}
@media  (max-width:768px) {
    #HomeTitle {
        font-weight: bold !important;
        font-size: 20px;
        color: #003466;
        margin-bottom: -10px;
    }
    #UsersName {
        font-weight: bold !important;
        font-size: 20px;
        color: #003466;
    }
    .titleRows {
        margin-left: 0px;
    }


    
}