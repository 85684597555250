.scrollable-content {
    height: 300px;
    overflow-y: scroll;
}

.list-item.selected {
    --background: #ff7100;
    color: #ffffff;
}

.list-item {
    cursor: pointer;
    color: #003466;
    font-weight: 600;
    font-size: 14px;
}

.list-item:hover {
    cursor: pointer;
}