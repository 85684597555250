
.invoiceDetailsText{
font-size: 13px !important;
color: #003466;
}
.invoiceMainTitle{
    color: #003466;
    font-weight: bold;
    font-size: 30px;
}

.invoiceBorder{
    border-bottom: 1px #A7A7A7 solid;
}

@media (max-width: 768px){
    .invoiceDetailsText{
        font-size: 11px !important;
        color: #003466;
        }
        .invoiceMainTitle{
            color: #003466;
            font-weight: bold;
            font-size: 20px;
        }
}